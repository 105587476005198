import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "SM Content Creation & Maintenance",
  whatWeOffer: {
    leftPart: "Posts on social media have to be smart. Depending on the platform you are using, you need to create posts that will resonate with your audience. Moreover, it would help if you did this constantly.",
    rightPart: "This is where our team can help you immensely. Our years of experience have taught us that you cannot use the same tactics for Facebook, Twitter, and Instagram. Each of these platforms is specific."
  },
  processText: "We can help you by writing and posting updates that are specifically tailored to your needs. Also, we can do this regularly, thus providing you with maintenance services. Contact us today for a quote.",
  testimonials: testimonials
}

const meta = {
  title: "SM Content Creation & Maintenance",
  description: "Posts on social media have to be smart. Depending on the platform you are using, you need to create posts that will resonate with your audience. Moreover, it would help if you did this constantly."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/social-media-content-creation";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)